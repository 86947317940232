//
// Sticky sidebar
//

;(function ($) {
    'use strict';

    $('.sticky-sidebar').stickySidebar({
        topSpacing:    30,
        bottomSpacing: 30,
        // resizeSensor:  true
    });
})(jQuery);